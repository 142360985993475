@import "FRONTEND/css/_variables.scss";


.rudolf-qr-card-scan-container {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  left: 0;
  bottom: calc(-100dvh);
  z-index: 8000;
  background: #fff;
  list-style: none;

  transition: bottom 0.1s linear;

  &.show {
    bottom: 0;
  }

  .rudolf-qr-card-scan-close {
    cursor: pointer;
    height: 50px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 50px;
    z-index: 8001;
    background-color: rgba(255, 255, 255, .6);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .qr-card-scan-camera-container {
    height: calc(100%);
    position: relative;
    overflow: hidden;

    .camera-status-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
    }

    .qr-code-scanner-text {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      z-index: 8001;
      color: #000;
      text-align: center;
      background-color: rgba(255, 255, 255, .6);
      padding: 0 7px;
    }
  }
}


