@import "FRONTEND/css/_variables.scss";


.lead-details {
  padding: 12px;
}

@media screen and (max-width: $tablet - 1) {
  .lead-details {
    padding: 20px;
    background: #fff;
  }
}

@media screen and (max-width: $tablet - 1) {
  .lead-content {
    padding: 0 20px 20px 20px;
    background: #fff;
  }
}

@media screen and (min-width: $tablet) {
  .lead-content {
    padding: 12px;
  }
}


.lead-content {
  .products-header {
    display: flex;
    align-items: center;
    column-gap: 20px;

    margin-bottom: 0.75rem;
  }


  .product-scroll-left-container,
  .product-scroll-right-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 170px;
    top: 3px;
    margin-top: 10px;

    color: $grey;

    -webkit-transition: color 0.3s ease, transform 0.3s ease;
    -moz-transition: color 0.3s ease, transform 0.3s ease;
    -ms-transition: color 0.3s ease, transform 0.3s ease;
    -o-transition: color 0.3s ease, transform 0.3s ease;
    transition: color 0.3s ease, transform 0.3s ease;

    &:hover {
      color: #000;
    }
  }

  .product-scroll-left-container {
    left: 0;

    &:hover {
      transform: translate3d(5px, 0, 0);
    }
  }

  .product-scroll-right-container {
    right: 0;

    &:hover {
      transform: translate3d(-5px, 0, 0);
    }
  }


  .lead-content-separator {
    border-bottom: 1px solid #EAEAEA;
    height: 1px;
    margin: 0 -12px 0 -12px;
  }


  .lead-product-scroll-container {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    overflow-x: hidden;

    margin: 0 40px;

    padding-top: 2px; // otherwise box-shadow clips on top

    cursor: pointer;

    &.left-fade {
      -webkit-mask-image: linear-gradient(to left, black 90%, transparent 100%);
      mask-image: linear-gradient(to right, left 90%, transparent 100%);
    }

    &.both-fade {
      -webkit-mask-image:
        linear-gradient(to left, black 90%, transparent 100%),
        linear-gradient(to right, black 90%, transparent 100%);
      -webkit-mask-size: 100%;
      -webkit-mask-position: center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-composite: source-in;

      mask-image:
        linear-gradient(to left, black 90%, transparent 100%),
        linear-gradient(to right, black 90%, transparent 100%);
      mask-size:  100%;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-composite: source-in;
    }

    &.right-fade {
      -webkit-mask-image: linear-gradient(to right, black 90%, transparent 100%);
      mask-image: linear-gradient(to right, black 90%, transparent 100%);
    }


    &.dragging {
      cursor: grabbing;
    }

    .lead-product:first-child { margin-left: 11px; }
    .lead-product:last-child { margin-right: 11px; }

    .lead-product {
      flex: 0 0 270px;
      width: auto;
      height: 170px;
      max-width: 100%;
      margin: 10px 24px 12px 3px;

      padding: 10px;

      box-sizing: border-box;

      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

      border-width: 0px;
      border-radius: 4px;

      line-height: 1.2rem;

      &.selected {
        background-color: #eaeaea;
        border-width: 2px;
        border-style: solid;
        padding: 8px;

        .product-dots {
          top: 7px;
          right: 0px;
        }
      }

      .product-title {
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        margin: 0 auto 12px auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 210px;
      }

      .product-dots {
        position: absolute;
        top: 9px;
        right: 2px;

        .product-dropdown-content {

          .product-dropdown-item {
            display: flex;
            padding: 3px 5px;

            font-weight: 500;

            font-size: 1rem;

            &:hover {
              background-color: #f6f6f6;
            }
          }
        }
      }

      .status-header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
        color: $text;

        .status-indicator {
          width: 20px;
          height: 20px;
          min-width: 20px;
          margin-right: 5px;
          border-radius: 4px;
          position: relative;
        }

        .status-name {
          display: inline-block;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .assignment-info {
        display: flex;
        margin-bottom: 10px;
        justify-content: center;

        .assignment-info-left,
        .assignment-info-right,
        .assignment-change-dropdown {
          overflow: hidden;
          flex-grow: 1;

          // Somehow this works?
          width: 10px;
        }

        .assignment-info-left {
          flex-basis: 45%;
          text-align: right;
          margin-right: 5px;
          color: $grey;
          font-weight: 500;
        }

        .assignment-info-right {
          flex-basis: 55%;
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-left: 5px;
          color: $text;
        }

        &.edit {
          margin-bottom: 0;
          align-items: center;

          .assignment-change-dropdown {
            flex-basis: 55%;
          }
        }

      }

    }
  }



  /********************************************************************
  *********************************************************************

  ██████╗ ███████╗██████╗  ██████╗ ██████╗ ████████╗
  ██╔══██╗██╔════╝██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝
  ██████╔╝█████╗  ██████╔╝██║   ██║██████╔╝   ██║
  ██╔══██╗██╔══╝  ██╔═══╝ ██║   ██║██╔══██╗   ██║
  ██║  ██║███████╗██║     ╚██████╔╝██║  ██║   ██║
  ╚═╝  ╚═╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝

  Report view styles

  *********************************************************************
  ********************************************************************/

  .report {
    .reportbox-grey {
      background-color: #f2f2f2;
      border-radius: 8px;
      padding: 10px;
    }

    .status-selection {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .status-color {
        width: 20px;
        height: 20px;
        min-width: 20px;
        margin-right: 12px;
        border: 1px solid #959BA3;
        border-radius: 4px;
        position: relative;
      }
    }


    .questions-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      justify-content: flex-start;
      position: relative;
      padding-top: 5px;


      .button.icon-button.is-light {
        margin-top: 4px;
      }

      .question {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;

          width: calc(33% - 14px); // random? seems to work

          .question-container {
              border: 1px solid #D9D9D9;
              border-radius: 8px;
              font-weight: 500;

              padding: 16px 14px 14px 14px;

              width: 100%;

              &.question-required-error {
                border-color: #f14668;
              }

          }

          .field > .help,
          .question-type-text {
            font-weight: 500;
            font-size: 0.857rem;
            color: #959BA3;
            padding-top: 2px;
            padding-left: 10px;
            margin: 0;
          }

      }
    }

    @media screen and (max-width: $tablet - 1) {
      .questions-list {
        flex-direction: column;
        flex-wrap: nowrap;

        .question {
          width: 100%;
        }
      }
    }

    .save-report {
      margin-top: 5px;
      margin-bottom: 24px;
    }


    .comments {
      border-radius: 8px;
      border: 1px solid #D9D9D9;
      padding: 8px;
      height: 440px;

      .comments-tabs {
        display: flex;
        justify-content: space-between;

        .comments-view,
        .report-history-view {
          text-decoration: underline;

          &:hover {
            cursor: pointer;
            text-decoration: none;
          }
        }
      }

      .new-comment-area {
        display: flex;
        align-items: flex-start;
        margin: 14px 0;

        .user-avatar {
          margin-right: 8px;
        }

        .new-comment-textbox {
          flex-grow: 1;
          margin-top: 3px;

          &.active {
            .field > .control > .icon:not(.has-text-danger) > .mdi {
              color: #000;
            }
          }
        }
      }

      .comments-separator {
        border-bottom: 1px solid #d9d9d9;
        margin: 0 -8px;
      }


      .comments-list {
        height: 245px;
        overflow-y: auto;
        padding-right: 8px;
        margin-top: 14px;

        &.report-view { height: 320px; }

        @include rudolf-scrollbars;

        .comment {
          display: flex;
          align-items: flex-start;
          margin-bottom: 14px;

          .user-avatar {
            margin-right: 8px;
            min-width: 40px;
            max-width: 40px;
          }

          .comment-content {
            .comment-posted-by-at {
              font-size: 0.857rem;
              color: $grey;

              sup {
                font-size: .7em;
                position: relative;
                top: 1.5px;
                vertical-align: top;
              }

              strong { color: $grey; }

            }

            .comment-text {

            }
          }
        }

      }

    }

  }



  /********************************************************************
  *********************************************************************

  ██╗  ██╗██████╗ ██╗
  ██║ ██╔╝██╔══██╗██║
  █████╔╝ ██████╔╝██║
  ██╔═██╗ ██╔═══╝ ██║
  ██║  ██╗██║     ██║
  ╚═╝  ╚═╝╚═╝     ╚═╝

  KPI view styles

  *********************************************************************
  ********************************************************************/

  .kpi {
    .chart-month-select {
      width: 150px;
      margin-left: 25px;

      .button {
        padding: calc(.5em - 1px) 1em;
        padding-left: 9px;
        height: 2em;
      }

      .button.is-small {
        font-size: 1rem;
      }

      .button.is-select {
        font-weight: 600;
      }
    }


    .sales-ytd-totals {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;

      .sales-ytd-container {
        border-left: 1px solid #D9D9D9;
        padding: 10px 40px 10px 40px;
        flex-grow: 1;
        margin-right: 25px;
        min-width: 270px;
        max-width: 300px;

        .sales-ytd-value,
        .sales-ytd-vs-target {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
        }

        .sales-ytd-value {
          margin-bottom: 1.5rem;
        }
      }
    }

    .kpi-category-breakdown-table {
      height: 250px;
      overflow-y: hidden;
      margin: 0;
      border-bottom: 1px solid #D9D9D9;
      border-bottom-left-radius: 8px;

      .b-table {
        height: 100%;
      }

      .category-product-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .kpi-product-map-view {
      height: 250px;

      #kpi-product-map-component {
        height: 100%;
        border-radius: 8px;
      }
    }

    .kpi-sales-goal-input-container {
      display: flex;
      margin: 30px 0;
      align-items: center;
    }

    .monthly-actuals {
      text-align: right;
      color: $grey;
      font-size: 0.857rem;
    }
  }

}


.lead-unsaved-changes-modal-list {
    list-style-type: disc;
    display: inline-block;
    margin-bottom: 15px;
    text-align: left;
}

