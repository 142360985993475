@import "FRONTEND/css/_variables.scss";


.mobile-top {
  align-items: center;
  background: #fff;
  display: flex;
  gap: 10px;
  height: 52px;
  justify-content: center;
  padding: 0 10px;

  .mobile-top-left {
    .search {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: url('../../images/magnify-icon.svg') center center no-repeat;
      background-size: 2.4rem;

      &:hover {
        background-color: #f6f6f6;
      }

      &.active {
        background-image: url('../../images/magnify-icon-active.svg');
      }
    }

  }

  .mobile-top-center {
    flex-grow: 1;
    text-align: center;

    .product-selector {
      /* width: 100%; */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100dvw - 40px - 100px);
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: 0px;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.3rem;
    }

  }


  .account {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    background: url('../../images/account-icon.svg') center center no-repeat;
    background-size: 2.4rem;

    &:hover {
      background-color: #f6f6f6;
    }

    &.active {
      background-image: url('../../images/account-icon-active.svg');
    }
  }

  .pagetitle {
    max-width: calc(100dvw - 120px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .product-selector {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    .icon {
      color: #959BA3;
    }
  }

  .product-selector:hover .icon {
    color: #3B3B3B;
  }


  .dropdown-menu .dropdown-item .dropdown-product-name,
  .dropdown-menu .has-link a {
    font-size: 1.143rem;
    font-weight: 600;
    color: #3B3B3B;
  }

  .dropdown-menu .dropdown-item.is-active .dropdown-product-name,
  .dropdown-menu .dropdown-item.is-active,
  .dropdown-menu .has-link a.is-active {
    background-color: #fff;
    color: #EF6D6A !important;
  }

  .dropdown-menu .dropdown-item.is-active:hover .dropdown-product-name,
  .dropdown-menu .dropdown-item:hover,
  .dropdown-menu .dropdown-item.is-active:hover,
  .dropdown-menu .has-link a:hover,
  .dropdown-menu .has-link a.is-active:hover {
    background-color: #f6f6f6;
    color: #3B3B3B;
  }

}


