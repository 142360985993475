@import "FRONTEND/css/_variables.scss";
//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//


@charset "utf-8";


@import './font-faces.scss';
@import './_variables.scss';


$mdi-font-path: "~@mdi/font/fonts";

@import "~@mdi/font/scss/materialdesignicons";


// Import Bulma and Buefy styles 
@import "~bulma";
@import "~@ntohq/buefy-next/src/scss/buefy";

@import "~vue-toastification/src/scss/_variables";
@import "~vue-toastification/src/scss/_toastContainer";
@import "~vue-toastification/src/scss/_toast";
@import "~vue-toastification/src/scss/_closeButton";
@import "~vue-toastification/src/scss/_progressBar";
@import "~vue-toastification/src/scss/_icon";
@import "~vue-toastification/src/scss/animations/_bounce";




//
// Site-wide styling
//
html,
body {
	height: 100%;
    overflow: hidden;
    background-color: #F6F6F6;
}

#app {
    height: 100%;
}


@include media-low-res-or-big-dpi {
    html, body { font-size: 13px; }

    #app {
        --topNavHeight: 46px;

        .page-layout {
            --sidebarWidth: 88px;
            --innerMargin: 16px;
        }
    }
}

@include media-high-res-or-low-dpi {
    html, body { font-size: $body-size; }

    #app {
        --topNavHeight: 57px;

        .page-layout {
            --sidebarWidth: 122px;
            --innerMargin: 20px;
        }
    }
}

@include media-huge-dpi {
    html, body { font-size: 12px; }

    #app {
        --topNavHeight: 46px;

        .page-layout {
            --sidebarWidth: 80px;
            --innerMargin: 10px;
        }
    }
}

@media screen and (max-width: $tablet - 1) {
    html, body { font-size: 14px; }
}
  

.page-layout {
    display: flex;
    overflow: hidden;

    // Page height minus TopNav
    height: calc(100% - var(--topNavHeight));

    .b-sidebar {
        // Sidebar width at this res
        .sidebar-content { width: var(--sidebarWidth) !important; }
    }

    // Page content (right side of sidebar)
    .page-content-inner {
        flex-grow: 1;

        overflow-y: auto;
        overflow-x: hidden;

        // Page content margins    
        margin: var(--innerMargin);

        --innerHeight: calc(100% - (2 * var(--innerMargin)));
        --innerWidth: calc(100vw - var(--sidebarWidth) - (2 * var(--innerMargin)));
        --innerWidthFull: calc(100vw - (2 * var(--innerMargin)));

        // Page content width and height minus margins
        height: var(--innerHeight);
        width: var(--innerWidth);

        @include rudolf-scrollbars;
    }

}


.mobile-layout {
    height: calc(100dvh - 52px - 65px);
    overflow-x: hidden;
    overflow-y: auto;

    @include rudolf-scrollbars;
}


a { color: #1400FF; }


a.rlink,
a.rlink:link,
a.rlink:visited {
    color: #000;
    text-decoration: underline;  

    &.grey { color: $grey; }
}

a.rlink:hover {
    text-decoration: none;
}

a.rlink:active {
    color: #000;
    text-decoration: underline;
}


.icon svg { width: 1em; height: 1em; max-width: 80%; max-height: 80%; }


// Text title, normal/bigger
.rtitle {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.143rem;
}

// Text title, smallest
.rtitle.ssm {
    font-size: 0.857rem;
}

// Text title, normal
.rtitle.sm {
    font-size: 1rem;
}

// Text title, medium
.rtitle.me {
    font-size: 1.25rem;
}

// Text title, bigger
.rtitle.lg {
    font-size: 1.5rem;
}

// Text title, regular case
.rtitle.normalcase {
    text-transform: none;
}

.normalcolor { color: $text; }
.greytext { color: $grey; }
.extrasmalltext { font-size: 0.714rem; }
.smalltext { font-size: 0.857rem; }
.smallertext { font-size: 0.929rem; }
.normaltext { font-size: 1rem; }
.normalweight { font-weight: 400; }
.semibold { font-weight: 500; }
.bold { font-weight: 600; }
.normalcase { text-transform: none; }
.uppercase { text-transform: uppercase; }

.icon > .mdi.disabled-icon {
    color: #959BA3;
}


.separator {
    height: 1px;
    border-bottom: 1px solid #F6F6F6;
    margin-bottom: 12px;

    &.nomargins { margin: 0 -12px; }

    &.grey { border-bottom-color: #eaeaea; }
}


.r-collapse-normal {
    transition: height 300ms cubic-bezier(0.33, 1, 0.68, 1);
}


.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}


.linktext {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
}


sup {
    vertical-align: top;
    position: relative;
    top: -0.2em;
    font-size: 0.7em;
}



//
// Buttons
//
// is-primary is red rounded button, commonly used for "Save", "Ok", etc.
// is-light is commonly used for "Back", "Cancel", etc.
//

.button {
    font-weight: 600;
}


@include media-low-res-or-big-dpi {
    .button { height: 2.2rem; }
    .r-icon:before { font-size: 1.571rem !important; }
}

@include media-high-res-or-low-dpi {
    .r-icon:before { font-size: 1.714rem !important; }
}

@include media-huge-dpi {
    .button { height: 1.8rem; }
    .r-icon:before { font-size: 1.571rem !important; }
}


@media screen and (max-width: $tablet - 1) {
    .button:not(.is-select) {
        font-size: 1rem;
        font-weight: bold;
        padding: 20px 20px;
        border-radius: 8px;

        &.is-light {
            border: 1px solid #d9d9d9;

            &:focus {
                border: 1px solid #d9d9d9;
            }
        }
    }
}



.button:not(.is-select) {
    text-transform: uppercase;

}

.button.is-light { background-color: transparent; }
.button.is-light:hover { background-color: #F6F6F6; }

.button.is-light:disabled, .button.is-light[disabled] {
    background-color: #fff;
    opacity: 0.5;
}

.button.is-primary {
    box-shadow: 0px 1px 8px #00000029 !important;
}

.button.is-primary:hover { background-color: #EF6D6A; }

.button.is-primary:disabled, .button.is-primary[disabled] {
    background-color: #E50B07;
}

.button.is-small { font-size: 0.857rem; }

.button.is-small:not(.is-rounded) { border-radius: 4px; }



// Icon buttons
.button.icon-button.is-light {
    padding-top: 0;
    padding-bottom: 0;
    height: 1.6em;
    font-size: 1.571rem;
    border-radius: 8px;
}

.button.icon-button.is-light.is-small {
    padding: 0 12px;
    font-size: 1.286rem;
}

.button.icon-button.is-light.is-large {
    padding: 0 12px;
    font-size: 3.571rem;
    font-weight: 300;
    height: 0.7em;
}




//
// Nicer <select> with <b-dropdown>
//
.dropdown,
.dropdown-trigger  {
    width: 100%;
}

// <b-button type="is-select">, use inside <b-dropdown> trigger
.button.is-select {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    font-weight: 500;
    justify-content: space-between;
}

.button.is-select span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
}

.button.is-select:active,
.button.is-select:hover,
.button.is-select:focus {
    border-color: #959BA3;
    box-shadow: none;
}



//
// Input fields styling
//

// Input field label
// <b-field> with label-position="on-border" or "inside"
.field.is-floating-label > .label {
    font-size: 0.857rem;
    text-transform: uppercase;
    font-weight: 500;
    color: #3b3b3b;
    letter-spacing: 0.4px;
    background-color: #fff;
}

.field.is-grouped.is-grouped-multiline { row-gap: 24px; }


// Fix input fields icon sizing
.field > .control > .icon { height: 2.6em; }  

.field > .control > .icon:not(.has-text-danger) > .mdi,
.field > .control > .icon:not(.has-text-danger) > .mdi:before {
  font-size: 1.929rem;
  color: #959ba3;
}


.field:not(:last-child) {
    margin-bottom: 0;
}


.select select,
.input,
.taginput .taginput-container.is-focusable {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    font-weight: 500;

    // Chrome form autofill
    &:-webkit-autofill::first-line,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        font-size: 1rem !important;
        font-weight: 500 !important;
        font-family: "Montserrat", sans-serif !important;
        background-color: #fff;
        transition: background-color 5000s ease-in-out 0s;
    }
}

.select select:focus,
.input:not(.is-danger):focus,
.taginput .taginput-container.is-focusable:active,
.taginput .taginput-container.is-focusable:focus {
    border-color: #959BA3;
    box-shadow: none;
}

.input.square { border-radius: 4px; }

.input.input.is-small { font-size: 0.857rem; }

.input::placeholder {
    color: #959BA3;
}



// Sales graphs canvases
canvas {
    width: 100% !important;
}






.rudolfbox {
    border-radius: 8px;
    background-color: #fff;

    &.grey {
        background-color: #f2f2f2;
    }
}


.login-layout {
    width: 100vw;
    height: 100vh;
    min-height: 100%;
}


.autocomplete {
    .dropdown-menu {
        .dropdown-content {
            @include rudolf-scrollbars;
        }
    }
}
  


// Change some vue-toastification styles
.Vue-Toastification__toast-body { font-size: 1rem; z-index: 15000; }
.Vue-Toastification__close-button { color: #3b3b3b; }

.Vue-Toastification__toast--default > .Vue-Toastification__progress-bar { background-color: #C6C6C6; }

.Vue-Toastification__toast--error > .Vue-Toastification__close-button { color: #fff; opacity: 1; }




//
// Modals
//

// Modal sizes per breakpoint, as per design
// Size is modal box width + 20px margins on both sides (40px)
@include until($tablet)     { .modal > .modal-content { max-width: 353px !important; } }
@include from($tablet)      { .modal > .modal-content { max-width: 388px !important; } }
@include from($desktop)     { .modal > .modal-content { max-width: 460px !important; } }
@include from($widescreen)  { .modal > .modal-content { max-width: 520px !important; } }
@include from($fullhd)      { .modal > .modal-content { max-width: 604px !important; } }

@media screen and (max-height: 739px) {
    .modal > .modal-content {
        height: calc(100vh - 40px);
        max-height: calc(100vh - 40px);
    }
}
  
@media screen and (min-height: 740px) {
    .modal > .modal-content {
        height: 700px;
        max-height: 700px;
    }
}

.modal.fit-content-height > .modal-content {
    height: auto;
}

.modal {
    z-index: 3000;

    .modal-content {
        overflow: hidden;

        .rudolfbox {
            height: 100%;
            padding: 30px;

            .modal-header {
                display: flex;
                flex-wrap: nowrap;
        
                row-gap: 24px;
        
                align-items: center;
        
                height: 1.714rem;
                margin-bottom: 1.714rem;
            }

            .modal-title {
                flex-grow: 1;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding-right: 15px;
            }

            .modal-inner {
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                @include rudolf-scrollbars;
            }
    
            .b-tabs {
                height: calc(100% - 3.428rem);

                .tabs {
                    height: 2.7rem;
                    margin-bottom: 1.714rem;

                    ul {
                        align-items: flex-end;

                        li a { font-weight: 500; }

                        li.is-active a {
                            color: $text;
                            font-weight: 600;
                        }
                    }
                }
                .tab-content {
                    height: calc(100% - 2.7rem - 1.714rem - 2.5rem - 1.429rem);
                    overflow-y: auto;
                    padding: 0 10px 0 0;

                    @include rudolf-scrollbars;
                }
                
                .dropdown-menu .dropdown-content {
                  @include rudolf-scrollbars
                }
            }

            .b-tabs:not(:last-child) { margin-bottom: 0; }

            .bottom-buttons-area {
                bottom: 30px;
                position: absolute;
                right: 30px;
                height: 2.5rem;
            }

            p.help {
                position: absolute;
            }
        }

        .modal-alert,
        .modal-alert:before {
            color: #E50B07;
            font-size: 10.714rem !important;
            height: 150px;
            margin-top: 10px;
        }
    
        .modal-excel,
        .modal-excel:before {
            color: #10793F;
            font-size: 10.714rem !important;
            height: 150px;
            margin-top: 10px;
        }
    
        .modal-text {
            color: #444D63;
            font-weight: 500;
            margin-bottom: 16px;
            margin-top: 24px;
        }
    
        .buttons { justify-content: center !important; }
        .buttons.right { justify-content: flex-end !important; }
    }


    // Background fade / animation
    .modal-background {
        background: #3B3B3B 0% 0% no-repeat padding-box;
        background:rgba(23,23,23,0.24);
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
    
        animation-name: blurFade;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 0.1s;
    }

}



@keyframes blurFade {
    0% {
        backdrop-filter: blur(0);
        -webkit-backdrop-filter: blur(0);
    }
    100% {
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
    }
}



//
// Google Places Autocomplete
//

.pac-container {
    border-radius: 8px;
    padding-bottom: 5px;
    z-index: 9000;
}

.pac-item {
    font-family: $family-sans-serif;
    font-size: 0.857rem;
    color: $text;
    letter-spacing: -0.03em;
}


//
// Loading dots
//
.loading:after {
    display: inline-block;
    animation: dotty steps(1,end) 1s infinite;
    content: '';
  }
  
  @keyframes dotty {
    0%   { content: ''; }
    25%  { content: '.'; }
    50%  { content: '..'; }
    75%  { content: '...'; }
    100% { content: ''; }
  }