@import "FRONTEND/css/_variables.scss";


.topnav-buttons {
  display: flex;
  align-items: center;

  .advanced-filter-tool,
  .rnotifications {
    z-index: 1700;
  }

  .advanced-filter-tool {
    margin-right: 10px;
  }
}

